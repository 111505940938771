<template>
  <b-overlay :show="isLoading" rounded="sm">
    <component :is="userData === undefined ? 'div' : 'b-card'">
      <b-tabs v-if="userData">
        <!-- Tab: Account -->
        <b-tab active>
          <template #title>
            <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">اطلاعات کاربر</span>
          </template>
          <user-edit-tab-account
            :user-data="userData"
            :selfie="selfie"
            class="mt-2 pt-75"
            @submitEdit="submitEdit"
          />
        </b-tab>
      </b-tabs>
    </component>
  </b-overlay>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink, BOverlay } from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import userStoreModule from "../userStoreModule";
import UserEditTabAccount from "./UserEditTabAccount.vue";
import { UsersGetRequest } from "@/libs/Api/Users";
import { UsersUpdateByAdminRequest } from "@/libs/Api/Users";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
  title: "اطلاعات کاربر",
  data() {
    return {
      userId: this.$route.params.id,
      userData: null,
      selfie: null,
      isLoading: false,
    };
  },
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BOverlay,

    UserEditTabAccount,
  },
  async created() {
    await this.getUser();
  },
  methods: {
    async getUser() {
      try {
        this.isLoading = true;
        let _this = this;

        let usersGetRequest = new UsersGetRequest(_this);
        usersGetRequest.setUserId(_this.userId);
        await usersGetRequest.fetch(
          function (response) {
            if (response.isSuccess) {
              _this.userData = response.data;
              _this.selfie = response.data.selfieFileData;
              _this.userData.selfieFileData = null;
            }
          },
          function (error) {
            console.log(error);
          }
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async submitEdit() {
      try {
        this.isLoading = true;
        let _this = this;
        let usersUpdateByAdminRequest = new UsersUpdateByAdminRequest(_this);
        usersUpdateByAdminRequest.setParams(_this.userData);
        await usersUpdateByAdminRequest.fetch(
          function (content) {
            if (content === 1) {
              return _this.$toast({
                component: ToastificationContent,
                position: "bottom-center",
                props: {
                  title: `عملیات ناموفق`,
                  icon: "CloseIcon",
                  variant: "danger",
                  text: `این نام کاربری قبلا ثبت شده است.`,
                },
              });
            }
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: `عملیات موفق`,
                icon: "CheckIcon",
                variant: "success",
                text: `تغییرات اعمال شد.`,
              },
            });
            _this.getUser();
          },
          function (error) {
            console.log(error);
          }
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  setup() {
    const userData = ref(null);

    const USER_APP_STORE_MODULE_NAME = "app-user";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    store
      .dispatch("app-user/fetchUser", { id: router.currentRoute.params.id })
      .then((response) => {
        userData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          userData.value = undefined;
        }
      });

    return {
      // userData,
    };
  },
};
</script>

<style></style>
