<template>
  <div>
    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="`https://BourseSJapi.oregonserver.com/${selfie}`"
          :text="avatarText(userData.fullName)"
          :variant="`light-${resolveUserRoleVariant(userData.type)}`"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-1">
        {{ userData.fullName }}
      </h4>
      <div class="d-flex flex-wrap">
        <b-button variant="primary" @click="$refs.refInputEl.click()">
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            @input="inputImageRenderer"
          />
          <span class="d-none d-sm-inline">ویرایش</span>
          <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
        </b-button>
        <b-button
          variant="outline-secondary"
          class="ml-1"
          @click="userData.selfieFileData = 'Delete'"
        >
          <span class="d-none d-sm-inline">حذف</span>
          <feather-icon icon="TrashIcon" class="d-inline d-sm-none" />
        </b-button>
      </div>
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>
        <!-- Field: Username -->
        <b-col cols="12" md="4">
          <b-form-group label="نام کاربری" label-for="username">
            <b-form-input id="username" v-model="userData.userName" />
          </b-form-group>
        </b-col>

        <!-- Field:  familyName -->
        <b-col cols="12" md="4">
          <b-form-group label="نام و نام خانوادگی" label-for="familyName">
            <b-form-input id="familyName" v-model="userData.fullName" />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col cols="12" md="4">
          <b-form-group label="ایمیل" label-for="ایمیل">
            <b-form-input id="email" v-model="userData.email" type="email" />
          </b-form-group>
        </b-col>

        <!-- Field: mobile -->
        <b-col cols="12" md="4">
          <b-form-group label="موبایل" label-for="mobile">
            <b-form-input id="mobile" v-model="userData.mobile" />
          </b-form-group>
        </b-col>

        <!-- Field: password -->
        <b-col cols="12" md="4">
          <b-form-group label="رمز عبور کاربر" label-for="password">
            <b-form-input
              class="mt-1"
              id="password"
              v-model="userData.password"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="submitEdit"
    >
      ذخیره تغییرات
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormTextarea,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import useUsersList from "../users-list/useUsersList";

export default {
  data() {
    return {
      tempProfile: null,
      sexualityOptions: [
        { label: "مرد", value: 1 },
        { label: "زن", value: 2 },
      ],
      stateOptions: [
        { label: "وارد شده", value: 1 },
        { label: "فالو نکرده", value: 2 },
        { label: "آیدی اشتباه", value: 3 },
      ],
    };
  },
  computed: {
    name() {
      return this.userData.name + " " + this.userData.familyName;
    },
    today() {
      // let options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      // let test = new Date().toLocaleDateString('fa-IR',options);
      // return  test.replace(/([۰-۹])/g, token => String.fromCharCode(token.charCodeAt(0) - 1728));
      return new Date();
    },
  },
  methods: {
    createJalaliDate(param) {
      if (param !== null) {
        return new Date(param).toLocaleDateString("fa-IR");
      }
    },
    submitEdit() {
      this.$emit("submitEdit");
    },
  },
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormTextarea,
    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    selfie: {
      type: String,
    },
  },
  setup(props) {
    const { resolveUserRoleVariant } = useUsersList();

    const statusOptions = [
      { label: "فعال", value: 1 },
      { label: "غیرفعال", value: 2 },
    ];

    const permissionsData = [
      {
        module: "Admin",
        read: true,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: "Staff",
        read: false,
        write: true,
        create: false,
        delete: false,
      },
      {
        module: "Author",
        read: true,
        write: false,
        create: true,
        delete: false,
      },
      {
        module: "Contributor",
        read: false,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: "User",
        read: false,
        write: false,
        create: false,
        delete: true,
      },
    ];

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64, preview) => {
        // eslint-disable-next-line no-param-reassign
        props.userData.selfieFileData = base64;
      },
      previewEl
    );

    return {
      resolveUserRoleVariant,
      avatarText,
      statusOptions,
      permissionsData,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
